import { Layout } from "../../../../src/components/Layout.js";
import { Seo } from "../../../../src/components/Seo.js";
import { Container } from "../../../../src/components/Container.js";
import { ResumeIcon } from "../../../../src/components/icons/Resume.js";
import * as React from 'react';
export default {
  Layout,
  Seo,
  Container,
  ResumeIcon,
  React
};