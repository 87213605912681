module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":650,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"tracedSVG":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".md",".mdx"],"gatsbyRemarkPlugins":[{"resolve":"/Users/davidvalles/sw/web/dtjv.io/node_modules/gatsby-remark-code-titles","id":"2f4fcfd7-7d2e-5dcc-a351-1ef3918099a6","name":"gatsby-remark-code-titles","version":"1.1.0","modulePath":"/Users/davidvalles/sw/web/dtjv.io/node_modules/gatsby-remark-code-titles/index.js","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]},{"resolve":"/Users/davidvalles/sw/web/dtjv.io/node_modules/gatsby-remark-prismjs","id":"96900835-4b61-53a8-8a41-f46b0b717fb0","name":"gatsby-remark-prismjs","version":"6.2.0","modulePath":"/Users/davidvalles/sw/web/dtjv.io/node_modules/gatsby-remark-prismjs/index.js","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]},{"resolve":"/Users/davidvalles/sw/web/dtjv.io/node_modules/gatsby-remark-responsive-iframe","id":"14fa90d3-ae84-54a3-bb14-8c56bdcaa86e","name":"gatsby-remark-responsive-iframe","version":"5.2.0","modulePath":"/Users/davidvalles/sw/web/dtjv.io/node_modules/gatsby-remark-responsive-iframe/index.js","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]},{"resolve":"/Users/davidvalles/sw/web/dtjv.io/node_modules/gatsby-remark-images","id":"33d2f35a-13cf-5908-9c9e-afdf68c1d403","name":"gatsby-remark-images","version":"6.2.0","modulePath":"/Users/davidvalles/sw/web/dtjv.io/node_modules/gatsby-remark-images/index.js","pluginOptions":{"plugins":[],"maxWidth":768},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]}],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/Users/davidvalles/sw/web/dtjv.io","commonmark":false},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":768},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"David Valles","short_name":"David Valles","description":"A personal site by David Valles.","start_url":"/","background_color":"#fff","theme_color":"#3b82f6","display":"minimal-ui","icon":"src/images/logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"3ae3edfa276008c055ba1782c0cc2373"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
